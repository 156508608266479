<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {};
  },
  methods: {},
  mounted() { },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Ferramentas</h3>
        <p></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <router-link tag="a" to="/ferramentas">
          <div class="card">
            <div class="card-body text-center">
              <div class="d-flex align-items-center justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1)">
                  <path
                    d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z">
                  </path>
                  <path
                    d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z">
                  </path>
                </svg>
                <h5 class="m-0 ml-2 font-size-18">Encurtador de URL</h5>
              </div>
              <span class="bg-soft-dark rounded px-2 font-size-12 text-white">EM BREVE</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-4">
        <router-link tag="a" to="/ferramentas">
          <div class="card">
            <div class="card-body text-center">
              <div class="d-flex align-items-center justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1)">
                  <path
                    d="M3 11h8V3H3zm2-6h4v4H5zM3 21h8v-8H3zm2-6h4v4H5zm8-12v8h8V3zm6 6h-4V5h4zm-5.99 4h2v2h-2zm2 2h2v2h-2zm-2 2h2v2h-2zm4 0h2v2h-2zm2 2h2v2h-2zm-4 0h2v2h-2zm2-6h2v2h-2zm2 2h2v2h-2z">
                  </path>
                </svg>
                <h5 class="m-0 ml-2 font-size-18">Gerador de QRCode</h5>
              </div>
              <span class="bg-soft-dark rounded px-2 font-size-12 text-white">EM BREVE</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-4">
        <router-link tag="a" to="/ferramentas">
          <div class="card">
            <div class="card-body text-center">
              <div class="d-flex align-items-center justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1)">
                  <path d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zM4 18V6h16v12z">
                  </path>
                  <path d="M6 8h2v8H6zm3 0h1v8H9zm8 0h1v8h-1zm-4 0h3v8h-3zm-2 0h1v8h-1z"></path>
                </svg>
                <h5 class="m-0 ml-2 font-size-18">Gerador de EAN</h5>
              </div>
              <span class="bg-soft-dark rounded px-2 font-size-12 text-white">EM BREVE</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </Layout>
</template>
